import './scss/style.scss';
import 'bootstrap';
import { WOW } from 'wowjs';
import { Swiper } from 'swiper/js/swiper.esm.js';

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html", function(response, status, xhr) {
    $('.nav-item').removeClass('active');
    $($('body').data('page')).addClass('active');
});

$('footer').load("footer.html", function(response, status, xhr) {
    wow.init();
});

$('.block-our-services-main').load("block-our-services-main.html", function(response, status, xhr) {
    wow.init();
});

$('.block-know-rights-main').load("block-know-rights-main.html", function(response, status, xhr) {
    wow.init();
});

$('.block-contact-main').load("block-contact-main.php", function(response, status, xhr) {
    wow.init();
});

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiper = new Swiper('#slider-main', {
        autoplay: {
            delay: 5000,
        },
        loop: true
    });
}

/*Script que agrega fondo azul al header cuando se hace scroll*/
$(window).on("scroll", function(e) {

    var header_fixed = parseInt(Math.round($('body').offset().top));

    if (parseInt(Math.round($(window).scrollTop())) > header_fixed) {
        $('header').addClass('scroll-active');
    } else {
        $('header').removeClass('scroll-active');
    }
});

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();